.scrollbar {
    overflow: auto;

    &.scrollbar-primary {
        &::-webkit-scrollbar {
            width: 8px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #4285F4;
        }

        scrollbar-color: #4285F4 #F5F5F5;
    }

    &.scrollbar-danger {
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #F5F5F5;
            border-radius: 4px;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #ff3547;
        }

        scrollbar-color: #ff3547 #F5F5F5;
    }

    &.scrollbar-warning {
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #F5F5F5;
            border-radius: 4px;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #FF8800;
        }

        scrollbar-color: #FF8800 #F5F5F5;
    }

    &.scrollbar-success {
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #F5F5F5;
            border-radius: 4px;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #00C851;
        }

        scrollbar-color: #00C851 #F5F5F5;
    }

    &.scrollbar-info {
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #F5F5F5;
            border-radius: 4px;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #33b5e5;
        }

        scrollbar-color: #33b5e5 #F5F5F5;
    }

    &.scrollbar-default {
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #F5F5F5;
            border-radius: 4px;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #a9a9a9;
        }

        scrollbar-color: #a9a9a9 #F5F5F5;
    }

    &.scrollbar-secondary {
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #F5F5F5;
            border-radius: 4px;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #aa66cc;
        }

        scrollbar-color: #aa66cc #F5F5F5;
    }

    &.scrollbar-dark {
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #343a40;
            border-radius: 4px;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: #343a40;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #a9a9a9;
        }

        scrollbar-color: #a9a9a9 #343a40;
    }
}


body {
    overflow: hidden;

    #root {
        .wrapper {
            .sidebar {
                height: calc(100vh - 3rem);
                overflow-y: auto;
                overflow-x: hidden;
            }

            .content-wrapper {
                height: calc(100vh - 3rem);
                overflow: auto;
                display: flex;
                flex-direction: column;

                .content {
                    flex: 1;
                }
            }

            .main-footer {
                margin: 0 !important;
            }
        }
    }
}
