.form-image {
    border-bottom: 1px solid;
    margin-bottom: 15px;
    padding: 15px;
}

.library-image {
    list-style: none;
    padding: 10px 0px;

}

.library-image .item {
    width: 135px;
    height: 145px;
    background: black;
    margin: 10px;
    position: relative;
    display: inline-block;
    cursor: pointer;

}

.library-image .item img {
    width: 100%;
    height: 145px;
    border: 1px solid;
}
.library-image .item img:hover {
    border: 3px solid #0069d9;
}

.library-image .item .name {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    padding: 7px 6px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 13px;
}

.root-image {
    position: relative;
}

.root-image .action {
    position: absolute;
    top: 0;
    width: 150px;
    height: 40px;
    padding: 7px 6px;
    color: white;
    background-color: red;
    font-size: 13px;

}
.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}
.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;

}


.react-contextmenu-item {
    background: 0 0;
    color: #373a3c;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
    color: #009688;
}
.react-contextmenu-item:hover {
    background: #0069d9;
    color: white;
}
.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}
.tree_folder .active{
    font-weight: 500;
    color: #00216E;
}
.page-link {   
    cursor: pointer;
    color: #007bff;
}