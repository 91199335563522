.form-select {
    &.form-control {
        padding: 0;
        height: auto;

        .dropdown {
            display: block;

            .dropdown-trigger {
                padding: 0;
                line-height: inherit;
                display: flex;
                outline: none;
                border: none;
                align-items: center;

                &::after {
                    font-size: .7rem;
                    color: #495057 !important;
                }

                .tag-list {
                    width: calc(100% - 1.25rem);
                    display: inline-block;

                    .tag-item {
                        margin: 5px;

                        .tag {
                            background-color: #007bff;
                            border-color: #006fe6;
                            padding: 0 0 0 4px;
                            color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .tag-remove {
                                color: #fff;
                                outline: none;
                                font-size: 14px;
                                width: 18px;
                                height: 18px;                               
                                :hover{
                                    background-color: #0062cc;
                                    border-color: #005cbf;
                                }
                            }
                        }

                        .placeholder {
                            display: inline;    
                            color: #939ba2;
                        }
                        &+.tag-item:last-child {
                            display: none;
                        }
                       

                        &:last-child {
                            margin: 6px;
                        }
                    }

                }
            }

            &.radio-select,
            &.simple-select {
                .tag-list {
                    display: flex;

                    .tag-item {
                        flex: 1;

                        &+.tag-item {
                            display: none;
                        }

                        .tag {
                            background-color: transparent;
                            border-color: transparent;
                            color: #495057;

                            .tag-remove {
                                color: #495057;
                            }
                        }
                    }

                }
            }
        }
        .dropdown-content {
            width: calc(100% + 2px);
            margin-top: -1px;
            margin-left: -1px;
            border: 1px solid #ced4da;
            box-shadow: none;
            border-top-color: #80bdff;
            padding: 0;

            .node {
                padding: 0;
                display: flex;
                align-items: center;

                &.checked {
                    background-color: #dee2e6;

                }

                &:hover {
                    background-color: #007bff;
                    color: #fff;
                }

                label {
                    font-weight: normal;
                    margin-bottom: 0px;
                    flex: 1;
                    padding: 6px 12px 6px 12px;
                    white-space: pre-line;
                }

                i.toggle+label {
                    padding-left: 0px;
                }

                .radio-item,
                .checkbox-item {
                    display: none;
                }

                .toggle {
                    outline: none;
                    margin-right: 0;

                    &::after {
                        display: flex;
                        width: 14px;
                        height: 14px;
                        align-items: center;
                        justify-content: center;
                        margin-left: 6px;
                        margin-right: 2px;
                        font-size: .8rem;
                        border: 1px solid #ced4da;
                    }
                }
            }


            .search {
                margin: 6px;
                display: block;
                width: calc(100% - 12px);
                ;
                height: calc(2.25rem + 2px);
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                background-color: #ffffff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

                &:focus {
                    border-color: #80bdff;
                }
            }
        }
        &:invalid,
        &.is-invalid {
            padding-right: 1.25rem;
            .dropdown-content {
                width: calc(100% + 1.25rem + 2px);
            }
        }

        &.form-control-sm {
            .dropdown .dropdown-trigger .tag-list .tag-item {
                margin: 3px;

                &:last-child {
                    margin: 4px;
                }

            }
            .dropdown .dropdown-content{
                margin-top: 0px;
                .search{
                    height: calc(1.8125rem + 2px);
                    padding: 0.25rem 0.5rem;
                    font-size: 0.875rem;
                    line-height: 1.5;
                    border-radius: 0.2rem;
                }
    
            }  
        }

        &:focus-within {
            border-color: #80bdff;
        }
    }

}