﻿@media(min-width:1200px) {
    .container {
        max-width: 1000px
    }
}

@font-face {
    font-family: open sans bold;
    src: url('../assets/fonts/OpenSans-Bold.ttf')
}

body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    background-color: #f6f6f8 !important;
    overflow: auto;

}

.clearfix {
    clear: both
}

p {
    line-height: 25px
}

a {
    color: #000
}

ul,
li,
ol {
    list-style-type: none
}

.font-11 {
    font-size: 11px !important
}

.fb-page,
.fb-page iframe[style],
.fb-page span {
    width: 100% !important
}

.font-12 {
    font-size: 12px !important
}

.font-13 {
    font-size: 13px !important
}

.font-14 {
    font-size: 14px !important
}

.font-16 {
    font-size: 16px !important
}

.font-18 {
    font-size: 18px !important
}

.font-20 {
    font-size: 20px !important
}

.font-22 {
    font-size: 22px !important
}

.font-24 {
    font-size: 24px !important
}

.font-48 {
    font-size: 48px !important
}

.w-20 {
    width: 20% !important
}

.w-80 {
    width: 80% !important
}

.main-color {
    color: #ea0035 !important
}

.bg-intro-1 {
    background: #23075b
}

.btn-intro-1,
.btn-intro-2,
.btn-intro-3,
.btn-intro-4 {
    background: #00000045;
    padding: 6px 40px
}

.h2,
h2 {
    font-size: 1.6em;
    line-height: 1.3
}

h1,
h2,
h3,
h4,
h5,
h6 {
    // font-family: roboto, sans-serif
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #ea0035
}

.bg-intro-2 {
    background: #f5a100
}

.bg-intro-3 {
    background: #ea0035
}

.intro-company {
    background: #fff
}

#menu {
    background: #fff
}

#header {
    height: 40px;
    background: #23075b;
    line-height: 40px;
    color: #fff;
    font-size: 14px
}

.right-header {
    text-align: right
}

.left-header span {
    color: #fff
}

#banner {
    width: 100%
}

#schedule {
    background: #fff
}

.top-inner {
    border: 1px solid #ccc
}

.intro-item {
    top: -30px
}

.intro-content .selected-table {
    color: #fff;
    font-size: 18px;
    font-weight: 700
}

#event {
    background-color: #f6f6f7bd
}

.btn-detail-event {
    background: #23075b
}

.intro-item .rounded {
    border-radius: .55rem !important
}

#ads .img-ads {
    width: 756px
}

#ads .container {
    padding: 0 7px
}

#ads img {
    width: 100%;
    height: auto
}

#honors {
    background: #f6f6f7bd;
    width: 100%;
    background-size: cover
}

.home-content .top-box .row .top-box-item {
    padding: 0 7px;
    z-index: 1
}

.top-title {
    font-size: 14px;
    // font-family: Roboto;
    font-weight: 700;
    background: #23075b;
    color: #fff;
    text-align: center;
    padding: 11px 10px;
    border-radius: 0;
    text-transform: uppercase;
    margin: 0
}

.top-inner {
    background: #fff;
    border-radius: 0
}

.tab-header {
    width: 100%;
    border-bottom: 1px solid #e4e4e4;
    float: left;
    background: #f3f3f3
}

.tab-header ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.tab-header li {
    float: left;
    position: relative
}

.width-50 {
    width: 33.3% !important
}

.width-25 {
    width: 24% !important
}

.width-28 {
    width: 28% !important
}

.tab-header li a.active {
    color: #364859;
    position: relative;
    background: #fff
}

.tab-header li a {
    color: #707171;
    padding: 14px 0 9px;
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    border-right: 1px solid #e4e4e4;
    font-size: 12px
}

.tab-header li a.active::after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -1px;
    left: 0;
    background: #fff
}

.statistical-number p {
    color: #fff
}

.line-yellow {
    width: 60px;
    height: 4px;
    background: #f68701;
    display: inline-block
}

.content-statistical .rank-number {
    border-radius: 50px;
    width: 30px;
    height: 30px;
    padding: 4px;
    border: solid 1px #f68701;
    color: #f68701;
    font-weight: 700
}

.search-list {
    text-align: right
}

.search-statistical button {
    color: #1a46ab;
    font-weight: 700
}

.search-statistical button:hover {
    color: #1a46ab;
    font-weight: 700
}

.search-statistical a {
    color: #fff;
    background: 0 0;
    border: 1px solid #ccc
}

.nav-item a {
    font-size: 16px;
    text-transform: none;
    font-weight: 700
}

.dropdown:hover>.dropdown-menu {
    display: block
}

.dropdown>.dropdown-toggle:active {
    pointer-events: none
}

#lib,
#ads {
    background: #fff !important
}

#news {
    background-color: #f6f6f7
}

// .paper-item {
//     -webkit-box-shadow: 3px 3px 5px 0 rgba(218, 217, 217);
//     -moz-box-shadow: 3px 3px 5px 0 rgba(218, 217, 217);
//     box-shadow: 3px 3px 5px 0 rgba(218, 217, 217);
//     border-radius: 8px
// }

.img-paper-item {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden
}

.img-paper-item img {
    width: 100%;
    max-height: 220px
}

#footer {
    background: #23075b
}

.menu-footer p {
    border-bottom: solid 1px #373737
}

#footer-end {
    display: none;
    background: #111
}

.social-footer {
    text-align: right
}

.timeline article {
    padding: 0 0 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd
}

.clearfix:after,
.clearfix:before {
    content: " ";
    display: table
}

.timeline .title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    display: block;
    margin-bottom: 5px
}

.timeline .thumb {
    float: left;
    margin: 5px 10px 0 0
}

.timeline a {
    color: #005cb9
}

.timeline .meta {
    margin-bottom: 3px;
    margin-top: 5px
}

time .fa {
    margin-right: 4px
}

.icon-clock:before {
    content: "\e081"
}

.fa.fa-angle-double-right,
.fa.fa-home {
    padding: 0 5px
}

.summary {
    color: #1d1d1d;
    line-height: 23px
}

.breadcrumb a {
    color: #000
}

.breadcrumb {
    padding-left: 0 !important;
    background: 0 0 !important
}

.site-banner {
    display: none;
    //background: url(../images/tin-tuc.png);
    width: 100%;
    text-align: center;
    min-height: 180px;
    align-items: center;
    justify-content: center;
    color: #fff
}

#content .container {
    background: #fff;
    margin-top: 5px
}

.pagination .active a {
    z-index: 3;
    color: #fff;
    cursor: default;
    background: #333
}

.pagination li a {
    line-height: 25px;
    float: left;
    padding: 0 10px;
    height: 24px;
    margin-right: 3px;
    background-color: #f1f1f1;
    text-align: center
}

.pagination .active a {
    z-index: 3;
    color: #fff;
    cursor: default;
    background: #333
}

.nav-right {
    background: #fff
}

.nav-right h3 {
    text-transform: uppercase;
    font-size: 15px;
    background: #23075b;
    padding: 8px 10px;
    color: #fff;
    font-weight: 700;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.nav-right ul {
    padding-left: 20px
}

.nav-right ul li {
    padding: 5px 0;
    text-transform: uppercase
}

.content-competition {
    padding: 0 10px
}

label {
    display: inline-block;
    margin-bottom: .1rem
}

select.form-control[multiple],
select.form-control[size] {
    height: 100px !important
}

select,
select option {
    color: #000
}

select:invalid,
select option[value=""] {
    color: #999
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 31px !important
}

.select2-container .select2-selection--single {
    height: 35px !important
}

.select2-container--default .select2-selection--single {
    border: 1px solid #ccc !important
}

@media screen and (max-width:991px) {
    #header {
        display: inline-table;
        font-size: 13px
    }

    .right-header {
        text-align: center
    }

    .intro-item {
        top: unset;
        margin-top: 2rem
    }

    #ads .img-ads {
        width: unset
    }

    .search-list {
        text-align: center;
        padding-bottom: 1rem
    }

    .search-result {
        text-align: center
    }

    .copy-right {
        text-align: center
    }

    .social-footer {
        text-align: center
    }

    .news-edu {
        margin-top: 1rem
    }

    #event img,
    #lib img {
        display: none
    }

    #honors .col-mobile {
        width: 50% !important
    }

    #honors .col-mobile .p-5 {
        padding: .5rem 0 !important
    }

    .navbar-light .navbar-toggler {
        position: absolute;
        right: 0;
        top: 7px
    }
}

.btn-detail span {
    padding: 0 10px;
    font-weight: 700
}

.title_detail {
    font-size: 20px
}

.icon-box-left,
.icon-box-right {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    width: 100%
}

.icon-box-left .icon-box-img,
.icon-box-right .icon-box-img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-bottom: 0;
    max-width: 200px
}

.icon-box-left .icon-box-img+.icon-box-text {
    padding-left: 1em
}

.icon-box-left .icon-box-text,
.icon-box-right .icon-box-text {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0;
    flex: 1 1 0px
}

.icon-box-img svg,
.icon-box-img img {
    padding-top: .2em;
    width: 100%
}

.des_news {
    font-weight: 700
}

.news-hot {
    padding: 0 8px !important
}

.news-hot li a:hover {
    text-decoration: none
}

.news-hot li {
    text-transform: none !important;
    border-bottom: 1px dotted #ccc
}

.news_new {
    color: #0f0f0f
}

.contact-icon .fa {
    font-size: 20px
}

.contact-icon a {
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    transition: all .5s ease
}

.a {
    position: relative;
    color: #000;
    display: inline-block
}

.label-success {
    background-color: #5cb85c
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em
}

.label-danger {
    background-color: #d9534f
}

.label-primary {
    background-color: #337ab7
}

#lib .table td,
.table th {
    border-top: none;
    font-weight: 700
}

#lib .table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6
}

#lib .table td {
    color: #0083ab
}

.province a {
    background: #23075b;
    padding: 5px 25px;
    color: #fff
}

.navbar-brand {
    padding: 0 0 3px
}

.navbar-brand img {
    height: 65px
}

.btn-primary {
    background: #23075b !important;
    border-color: #23075b !important
}

.footer-1 img {}

.text-red {
    color: #ea0035
}

.navbar-light {
    padding: 0 !important
}

.img_adv img {
    width: 100%
}

.home-content .top-box-item {
    padding: 0 7px;
    z-index: 1
}

.top-inner .top-list ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.top-inner .top-list {
    padding: 0 20px 15px
}

.top-inner .top-list ul li {
    padding: 20px 0 0;
    border-bottom: 1px solid #f2f4f5
}

.top-inner .top-list ul li:nth-child(1) .top-stt {
    background: #ea0035
}

.top-inner .top-list ul li:nth-child(2) .top-stt {
    background: #e8a40b
}

.top-inner .top-list ul li:nth-child(3) .top-stt {
    background: #e8a40b
}

.top-inner .top-list ul li .top-stt {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    font-size: 18px;
    // font-family: Roboto;
    font-weight: 700;
    text-align: center;
    background: #2095dc;
    color: #fff;
    line-height: 36px;
    float: left;
    margin-right: 20px;
    margin-bottom: 0
}

.top-inner .top-list ul li .top-address {
    font-size: 13px;
    color: #0082d0;
    // font-family: Roboto;
    font-weight: 700;
    line-height: 0
}

.top-inner .top-list ul li .top-count {
    font-size: 14px;
    line-height: 1.1
}

.top-inner .top-list ul li .top-count span {
    font-size: 16px;
    // font-family: Roboto;
    font-weight: 700
}

.tab:not(:first-child) {
    display: none
}

.news-left .news-inner {
    min-height: 347px
}

.news-left .news-inner .post-item:first-child {
    padding: 0 0 0
}

.news-left .news-inner .post-item {
    padding: 10px 0 0;
    border-bottom: 1px solid #f2f4f5
}

.news-left .news-inner img {
    max-width: 80px !important
}

.news-left .news-inner img {
    float: left;
    margin-right: 15px;
    background: #fff;
    padding: 1px;
    border: 1px solid #e1e5e9
}

.news-left .news-inner .post-item:first-child .post-title {
    margin-bottom: 10px
}

.news-left .post-title {
    font-size: 17px;
    // font-family: Roboto;
    margin-bottom: 5px;
    line-height: 1.4
}

.news-left .post-date {
    font-size: 11.5px;
    color: #999
}

#lib .box-title {
    padding: 0 7px
}

.news-title {
    // font-family: Roboto;
    font-size: 14px;
    color: #fff;
    padding: 11px 10px;
    margin: 0;
    border-radius: 0;
    text-transform: uppercase
}

.people-list {
    overflow-y: scroll;
    max-height: 370px;
    height: 366px
}

.people-list ul li .top-count span {
    font-size: 14px !important
}

.people-list ul li .top-address {
    font-size: 12px !important
}

.people-list ul li {
    padding: 10px 0 0 !important
}

.people-list::-webkit-scrollbar {
    width: 4px;
    height: 2em
}

.people-list::-webkit-scrollbar-button {
    background: #ccc
}

.people-list::-webkit-scrollbar-track-piece {
    background: #d8d5d5
}

.people-list::-webkit-scrollbar-thumb {
    background: #eee
}

â€‹ #owl-address ul li {
    display: block;
    width: 100%;
    height: auto
}

#owl-address .item {
    border-right: 1px solid #ccc;
    padding: 7px 0
}

#owl-address .item.active {
    background: #fff;
    font-weight: 700
}

.hideContent {
    overflow: hidden;
    line-height: 1em;
    height: 400px
}

.showContent {
    line-height: 1em;
    height: auto
}

a.label:hover {
    color: #fff
}

#owl-address,
.owl-nav {
    position: relative
}

#owl-address .owl-nav .owl-prev {
    top: -38px;
    position: absolute;
    left: 8px
}

#owl-address .owl-nav .owl-next {
    right: 4px;
    position: absolute;
    top: -38px
}

.owl-next span,
.owl-prev span {
    font-size: 25px
}

.select2 {
    width: 100% !important
}

@media screen and (max-width:480px) {
    .width-25 {
        width: 22% !important
    }

    .width-28 {
        width: 33% !important
    }
}

.address-list {
    padding: 0 0 !important
}

.address_place {
    overflow-y: scroll;
    max-height: 400px
}

.scroll_place {
    overflow-y: scroll;
    height: 400px;
    max-height: 400px;
    position: relative;
    left: 15px
}

.address_place::-webkit-scrollbar,
.scroll_place::-webkit-scrollbar {
    width: 4px;
    height: 2em
}

.address_place::-webkit-scrollbar-button,
.scroll_place::-webkit-scrollbar-button {
    background: #ccc
}

.address_place::-webkit-scrollbar-track-piece,
.scroll_place::-webkit-scrollbar-track-piece {
    background: #d8d5d5
}

.address_place::-webkit-scrollbar-thumb,
.scroll_place::-webkit-scrollbar-thumb {
    background: #eee
}

.form-control {
    color: #000 !important;
    font-size: 14px;

}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #000 !important
}

.content-list .timeline {
    padding-bottom: 25px
}

.account a.dropdown-item {
    height: 30px;
    line-height: 22px
}

.footer-padding,
#lib .box-title {
    padding: 0 7px
}

@media screen and (max-width:480px) {
    .video-home {
        margin-top: 10px !important;
        padding-top: 10px !important
    }

    iframe {
        width: 100% !important
    }

    .content_news img {
        width: 100% !important;
        height: auto !important
    }

    #header .left-header {
        font-size: 11px
    }

    .content-list .timeline {
        margin-bottom: 15px
    }

    .footer-padding,
    #lib .box-title {
        padding: 0 15px
    }
}

.info_parent {
    display: none
}

.content_news img {
    width: 100%
}

#map {
    background: #fff;
    // font-family: roboto, arial
}

.titleContentRoadMap {
    font-size: 14px;
    font-weight: 700
}

.contentContentRoadMap {
    font-size: 14px;
    line-height: 23px;
    padding-top: 5px
}

.contentRoadMap {
    padding: 15px;
    border: 1px solid #ea0035
}

.titleRoadMap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ea0035
}

.titleRoadMap div {
    font-weight: 700;
    color: #fff
}

#partner .carousel-indicators {
    left: 0;
    top: auto;
    bottom: -40px
}

#partner .carousel-indicators li {
    background: #a3a3a3;
    border-radius: 50%;
    width: 8px;
    height: 8px
}

#partner .carousel-indicators .active {
    background: #707070
}

.slideshow-container {
    position: relative;
    margin: auto
}

.slideshow-container .mySlides {
    display: none
}

.slideshow-container .prev,
.slideshow-container .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    transition: .6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none
}

.slideshow-container .next {
    right: 0;
    border-radius: 3px 0 0 3px
}

.slideshow-container .prev:hover,
.slideshow-container .next:hover {
    background-color: rgba(0, 0, 0, .8)
}

.slideshow-container .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0
}

.slideshow-container .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color .6s ease
}

.slideshow-container .fade {
    animation-name: fade !important;
    animation-duration: 1.5s !important
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

@media only screen and (max-width:300px) {

    .slideshow-container .prev,
    .slideshow-container .next,
    .slideshow-container .text {
        font-size: 11px
    }
}

#partner img {
    width: 100%
}

.partnerImg {
    padding: 15px
}

.contact-info a {
    color: #fff
}

.contact-info {
    color: #fff;
    display: flex;
    justify-content: space-between
}

.fb-content {
    position: -webkit-sticky;
    position: sticky;
    top: 0
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.45rem;
        padding-left: 0.45rem;
    }
}


.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

ul,
li,
ol {
    list-style-type: none;
}

.tag-list {
    display: inline;
    padding: 0;
    margin: 0;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
    padding: 4px;
    line-height: 20px;
    max-height: 200px;
    display: inline-block;
    overflow: auto;
    border: 1px solid #b9b9b9;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
    cursor: pointer;
}

.form-select.form-control .dropdown .dropdown-trigger::after {
    font-size: .7rem;
    color: #495057 !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
    content: "\25BC";
    vertical-align: middle;
    color: #3c3c3c;
    margin-right: 2px;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
    position: absolute;
    padding: 4px;
    z-index: 1;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
}

.form-select.form-control .dropdown-content {
    width: calc(100% + 2px);
    margin-top: -1px;
    margin-left: -1px;
    border: 1px solid #ced4da;
    box-shadow: none;
    border-top-color: #80bdff;
    padding: 0;
}

.react-dropdown-tree-select .dropdown .dropdown-content ul {
    margin: 0;
    padding: 0;
}

.form-select.form-control .dropdown .dropdown-trigger::after {
    font-size: .7rem;
    color: #495057 !important;
}

.tag-remove {
    color: #a0a0a0;
    font-size: 75%;
    line-height: 100%;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
    content: "\25B2";
    vertical-align: middle;
    color: #3c3c3c;
    margin-right: 2px;
}

.form-control.date-picker {
    padding: 0;
    border: none;
}

.react-datepicker-wrapper {
    width: 100%;
}

.form-control.date-picker .react-datepicker-wrapper .react-datepicker__input-container>input {
    background-color: initial;
    width: 100%;
    border: 1px solid #ced4da;
    outline: none;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
}

.input-group-append {
    margin-left: -1px;
}

.form-control.date-picker .input-group-append {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
}

.form-control.date-picker .input-group-append .input-group-text {
    border: none;
    background: transparent;
}

.form-select.form-control .dropdown-content .search {
    font-size: 14px;
}

.file-plus-kyc,
.file-plus {
    cursor: pointer;
}

.form-select.form-control .dropdown-content .node.disabled {
    color: #6c757d;
}

.react-dropdown-tree-select .dropdown .dropdown-content ul {
    max-height: 300px;
    overflow-y: auto;
}

.form-select.form-control .dropdown.radio-select .tag-list .tag-item .tag,
.form-select.form-control .dropdown.simple-select .tag-list .tag-item .tag {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.form-select.form-control .dropdown.radio-select .tag-list .tag-item,
.form-select.form-control .dropdown.simple-select .tag-list .tag-item {
    width: 100%;
}

.file-plus-kyc,
.file-plus {
    cursor: pointer;
}

#clickFirstPopup,
#showBtnUploadAvatar,
#clickFirstPopupFile1,
#showBtnUploadFile1,
.tickAvatar {
    display: none;
}

.owl-item.active .carousel-item {
    display: block;

}

.carousel-item{    
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.owl-carousel .owl-nav {
    justify-content: space-between;
    align-items: center;
    display: flex !important;
    position: static;
    top: auto;
    width: 100%;
    right: auto;
    margin-top: 0 !important;
    height: 0px;
}

.owl-carousel .owl-nav button {
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
}

.owl-carousel .owl-nav button:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.5;
    background: #b3b3b3 !important;
}
.owl-carousel .owl-nav button:focus, .owl-carousel .owl-nav button:focus-visible {
    outline: unset !important;
}



.owl-carousel .owl-nav button i {
    position: absolute;
    
    display: inline-block;
    width: 20px;
    height: 20px;
    top: 50%;
    font-size: 40px;    
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
}

.owl-carousel .owl-nav .owl-next {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}
.owl-carousel .owl-item img{
    max-height: 390px;
}

.owl-carousel .owl-nav .owl-prev {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
}

.donviphutrach {
    font-size: 14px;
    font-weight: bold;
    color: #ea0035;
    text-align: center;
}
.card{
    border: 1px solid rgba(0,0,0,.125);
}
.mainUser {
    font-size: 18px;
    color: #23075b;
    font-weight: bold;
    border-bottom: 3px solid #23075b;
}
.btn-danger {
    background-color: #ea0035 !important;
    border-color: #ea0035 !important;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #ea0035 !important;
    border-color: #ea0035 !important;
}